<template>
    <div>
        <div class='main-loader' v-if='isLoading'></div>
        <section class="pricing">
            <b-container>
                <div class="">
                    <div class="">
                        <div class="text-center">
                            <h1 class="">
                                Subscription Plans
                            </h1>
                            <p class="mb-2">
                                Select a plan to continue purchase.
                            </p>
                        </div>
                    </div>
                    
                    <b-row class="pricing-card justify-content-center match-height">
                        <b-col md="6" lg="4" v-for="(item, index) in membershipDetails" :key="index">
                            <b-card class="text-center">
                                <b-img v-if='item.membership_type_image_url==null' :src="require('@/assets/images/illustration/Pot2.svg')" class="mb-2 membership-default-image" alt="basic svg img" />
                                <b-img v-else :src="item.membership_type_image_url" class="membership-image mb-2" />
                                <h2 class="font-weight-bold text-uppercase text-dark">{{item.membership_type_name}}</h2>
                                <div class="up-pricing">
                                    <template v-if="item.membership_type_id === 600">
                                        <strong class="up-price text-uppercase text-primary">$100</strong>
                                        <span class="up-month small">per month</span>
                                    </template>
                                    <template v-else>
                                        <strong class="up-price text-uppercase text-primary">${{item.membership_price}}</strong>
                                        <span class="up-month small">per month</span>
                                    </template>
                                </div>
    
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item v-for="(benefit, bIndex) in item.benefit_lists" :key="bIndex">
                                        {{benefit.benefit_description}}
                                    </b-list-group-item>
                                </b-list-group>
                                <b-button block v-if="userType === 'nonmember' && item.membership_type_id === 598" variant="success" class="mt-2" disabled>
                                    Purchased
                                </b-button>
                                <b-button block v-else-if="item.membership_type_id === 600" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" class="mt-2" disabled>
                                    Purchase Now
                                </b-button>
                                <b-button block v-else v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" class="mt-2" @click='purchaseMembership(item)' disabled>
                                    Purchase Now
                                </b-button>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </section>
    </div>
    </template>
    
    <script>
    import {
        BFormCheckbox,
        BContainer,
        BRow,
        BCol,
        BCard,
        BImg,
        BCardText,
        BListGroup,
        BListGroupItem,
        BButton,
        BBadge,
        BTabs,
        BTab
    } from 'bootstrap-vue'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    export default {
        components: {
            BFormCheckbox,
            BContainer,
            BButton,
            BCardText,
            BListGroup,
            BListGroupItem,
            BRow,
            BCol,
            BCard,
            BBadge,
            BImg,
            BTabs,
            BTab,
            AppCollapseItem,
            AppCollapse,
            ToastificationContent
        },
        directives: {
            Ripple,
        },
        computed: {
        },
        created() {
            this.checkUserLoggedIn();
            this.getMembershipDetails();
        },
        data() {
            return {
                monthly: [],
                yearly: [],
                extra_amount: '',
                userData: [],
                role: "",
                isLoading: false,
                fullPage: true,
                loader: "bars",
                not_listed: '',
                noYearly: false,
                noMonthly: false,
    
                title: '',
                desc: '',
                imageUrl: '',
    
                company_name: '',
                term: '',
    
                userData: [],
                user_id: '',
                gst: '',
                user_role: '',
                
                membershipDetails: [], // Add this line
                userType: 'nonmember' // Add this line for the user type
            }
        },
        methods: {
            checkUserLoggedIn() {
                const userData = localStorage.getItem("userdetails")
    
                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                }
            },
            purchaseMembership(val) {
                if (this.user_id == null) {
                    this.$bvModal.show("modal-login");
                } else {
                    this.$router.push({
                        name: "membership-checkout",
                        query: {
                            id: val.membership_type_id,
                        }
                    });
                }
            },
            contactSales() {
                // Implement contact sales logic here
                console.log("Contact Sales");
            },
            async getMembershipDetails() {
                this.isLoading = true;
    
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                const userData = localStorage.getItem("userdetails");
    
                if (userData == null || undefined) {
                    this.user_id = null;
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                    this.gst = JSON.parse(localStorage.getItem("userdetails")).gst_in_percentage;
                    this.user_role = JSON.parse(localStorage.getItem("userdetails")).user_role;
                }
    
                var urlencoded = new URLSearchParams();
                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("user_role", this.user_role);
                urlencoded.append("gst_percentage", this.gst);
                urlencoded.append("user_id", this.user_id);
    
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };
    
                const rawResponse = await fetch(
                    process.env.VUE_APP_BASE_URL + "membership/getPublicMasterMembershipTypes",
                    requestOptions
                );
    
                const response = await rawResponse.json();
                let membershipDetails = response.data;
    
                this.membershipDetails = membershipDetails; // Store the response data
                this.isLoading = false;
            },
        }
    }
    /* eslint-disable global-require */
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/pages/page-pricing.scss';
    
    .pricing .nav-tabs {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    
    .pricing .nav-tabs .nav-link.active,
    .pricing .nav-tabs .nav-item.show .nav-link {
        background: #3369f2 !important;
        border: 1px solid #3369f2 !important;
        color: #fff !important;
        border-radius: 25px !important;
        padding: 0.61rem 1.2rem !important;
        outline: none !important;
    }
    
    .pricing .nav-tabs .nav-link {
        background-color: transparent !important;
        border-radius: 25px !important;
        color: #3369f2 !important;
        border-bottom: none !important;
        border: 1px solid #3369f2 !important;
        padding: 0.61rem 1.2rem !important;
        font-size: 1rem !important;
    }
    
    .pricing .nav-item:first-child {
        margin-right: 14px !important;
    }
    
    .pricing .nav-tabs .nav-link {
        margin-right: 0px !important;
    }
    
    .pricing-card .membership-default-image {
        height: 135px;
    }
    
    .pricing-card .membership-image {
        height: 256px;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover
    }
    
    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
    }
    
    .loader {
        color: #ffffff;
        /* Customize the color of the spinner */
    }
    </style>
    